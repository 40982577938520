import {gsap} from 'gsap';
import {MagneticFx} from './magneticFx';
import {calcWinsize, wrapElements} from './utils';
import {wrap} from 'gsap/gsap-core';
import $ from "jquery";


// Calculate the viewport size
let winsize = calcWinsize();
window.addEventListener('resize', () => winsize = calcWinsize());

const frameEl = document.querySelectorAll('.frame');

export class Home {
    constructor(el, itemsArr) {
        this.DOM = {el: el};
        this.itemsArr = itemsArr;
        // left/right(invert) align
        this.invert = this.DOM.el.classList.contains('item--invert');


        // image
        this.DOM.img = this.DOM.el.querySelector('.principal__img img');

        // principal__text
        this.DOM.pricipalText = this.DOM.el.querySelector('.principal__text');
        this.DOM.heading = this.DOM.pricipalText.querySelector('h1');

        this.DOM.footer = document.querySelector('footer');

       // this.DOM.header = document.querySelector('header');

        this.DOM.contentElHeadingHome = this.DOM.pricipalText.querySelector('h1');

        this.DOM.contentElTextHome = [...this.DOM.pricipalText.querySelectorAll('p')];

        // excerpt element
        this.DOM.principalNav = this.DOM.el.querySelector('.principal__nav');

        this.initEvents();
    }

    initEvents() {
        // // same for the "read more" link
        // this.DOM.excerptLink.addEventListener('mouseenter', () => this.onMouseEnter());
        // this.DOM.excerptLink.addEventListener('mouseleave', () => this.onMouseLeave());
        // this.DOM.excerptLink.addEventListener('click', () => this.open());
        //
        // this.DOM.backCtrl.addEventListener('click', () => this.close());
    }


    open() {
        if (this.timelineHoverClose) this.timelineHoverClose.kill();
        if (this.timelineHoverOpen) this.timelineHoverOpen.kill();



        this.timelineHoverOpen = gsap.timeline().addLabel('start', 0)
            .set(
                this.DOM.pricipalText,
                {
                    'clip-path': 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)',
                    opacity: 1,
                    y: 0,
                }
            )
            // .to('.principal__img img', {duration: .5, opacity: 1, x: 0}, '-=.3')

            // animate in the content chars
            .from(this.DOM.header, {
                duration: 1.3,
                ease: 'expo',
                opacity: 0,
            }, 'start')
            .from(this.DOM.contentElHeadingHome, {
                duration: 1.3,
                ease: 'expo',
                opacity: 0,
                y: '180%',
                onComplete: () => {
                },
                stagger: 0.03
            }, 'start+=0.1')
            // content text moves up and fades in
            .from(this.DOM.contentElTextHome, {
                duration: 1.3,
                ease: 'expo',
                y: '180%',
                opacity: 0,
                stagger: 0.03
            }, 'start+=0.7')
            .from(this.DOM.img,{
                opacity: 0,
                duration:0.8,
                y: "50%"
            }, 'start')
            .from(this.DOM.principalNav, {
                duration: 1.3,
                ease: 'expo',
                y: '180%',
                opacity: 0,
                stagger: 0.03
            }, 'start+=1')
            .from(this.DOM.footer, {
                duration: 1.3,
                ease: 'expo',
                y: '180%',
                opacity: 0,
                stagger: 0.03
            }, 'start+=1.3')
        ;
    }

    close() {
        if (this.timelineHoverOpen) this.timelineHoverOpen.kill();

        this.isContentOpen = false;

        $('nav.open')

        this.timelineHoverClose = gsap.timeline().addLabel('start', 0)
            //content text moves up and fades in
            .to(this.DOM.header, {
                duration: 1.3,
                ease: 'quad.in',
                opacity: 0,
                y: '-80%',
            }, 'start')
            .to(this.DOM.contentElHeadingHome, {
                duration: 0.3,
                opacity: 0,
                ease: 'quad.in',
                x: this.invert ? '-103%' : '103%',
            }, 'start')
            .to(this.DOM.contentElTextHome, {
                duration: 1.3,
                ease: 'expo',
                x: '-180%',
                opacity: 0,
                stagger: -0.03
            }, 'start+=0.4')
            .to(this.DOM.img,{
                opacity: 0,
                duration:0.8,
                y: "80%"
            }, 'start')
            .to(this.DOM.principalNav, {
                duration: 0.5,
                ease: 'power4.in',
                opacity: 0,
                y: '20%'
            }, 'start')
            .to(this.DOM.footer, {
                duration: 0.5,
                ease: 'power4.in',
                opacity: 0,
                y: '20%'
            }, 'start')
    }
}
