import {gsap} from 'gsap';
import {MagneticFx} from './magneticFx';
import {calcWinsize, wrapElements} from './utils';
import $ from "jquery";

// Calculate the viewport size
let winsize = calcWinsize();
window.addEventListener('resize', () => winsize = calcWinsize());

export class Contact {
    constructor(el, itemsArr) {
        this.DOM = {el: el};
        this.itemsArr = itemsArr;
        // left/right(invert) align
        this.invert = this.DOM.el.classList.contains('item--invert');


        // image
        this.DOM.img = this.DOM.el.querySelector('.principal__img img');

        // principal__text
        this.DOM.principalTitle = this.DOM.el.querySelector('.principal__title');
        this.DOM.pricipalText = this.DOM.el.querySelector('.principal__text');
        this.DOM.heading = this.DOM.pricipalText.querySelector('h1');

        this.DOM.footer = document.querySelector('footer');

        this.DOM.featureList = document.querySelectorAll('.text__list li');
        this.DOM.featureJionUs = document.querySelectorAll('.join__us__title, .join__us__text');
        this.DOM.contactUs = document.querySelectorAll('.contact__call p');

        this.DOM.header = document.querySelector('header');

        // create the heading texts structure for the characters sliding animation (split the text into characters)
        this.editContentLayout();

        // excerpt element
        this.DOM.principalNav = this.DOM.el.querySelector('.principal__nav');

        this.initEvents();
    }

    editContentLayout() {
        this.DOM.contentElHeading = this.DOM.pricipalText.querySelector('h1');
        this.DOM.contentHeadingChars = [...this.DOM.contentElHeading.querySelectorAll('.char')];
        //wrapElements(this.DOM.contentHeadingChars, 'span', 'char-wrap');
        this.DOM.contentElText = [...this.DOM.pricipalText.querySelectorAll('p')];
    }

    initEvents() {
        // // same for the "read more" link
        // this.DOM.excerptLink.addEventListener('mouseenter', () => this.onMouseEnter());
        // this.DOM.excerptLink.addEventListener('mouseleave', () => this.onMouseLeave());
        // this.DOM.excerptLink.addEventListener('click', () => this.open());
        //
        // this.DOM.backCtrl.addEventListener('click', () => this.close());
    }

    onMouseEnter() {
        if (this.timelineHoverOut) this.timelineHoverOut.kill();
        this.timelineHoverIn = gsap.timeline()
            .addLabel('start', 0);
    }

    onMouseLeave() {
        if (this.isContentOpen) return;

        if (this.timelineHoverIn) this.timelineHoverIn.kill();

        this.timelineHoverOut = gsap.timeline()
            .addLabel('start', 0);
    }

    open() {

        if (this.timelineHoverIn) this.timelineHoverIn.kill();
        if (this.timelineHoverClose) this.timelineHoverClose.kill();

        this.isContentOpen = true;
        // scroll related
        document.body.classList.add('oh');

        this.timelineHoverOpen = gsap.timeline().addLabel('start', 0)
            // .set(
            //     this.DOM.pricipalText,
            //     {
            //         'clip-path': 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)',
            //         opacity: 1,
            //         y: 0,
            //     }
            // )
            // .to('.principal__img img', {duration: .5, opacity: 1, x: 0}, '-=.3')

            // animate in the content chars
            .from(this.DOM.header, {
                duration: 1.3,
                ease: 'expo',
                opacity: 0,
            }, 'start')
            .from(this.DOM.principalTitle, {
                duration: 1.3,
                ease: 'expo',
                opacity: 0,
                y: '180%',
                stagger: 0.03
            }, 'start+=0.1')
            .from(this.DOM.contentElHeading, {
                duration: 1.3,
                ease: 'expo',
                opacity: 0,
                y: '180%',
                onComplete: (data)=>{},
                stagger: 0.03
            }, 'start+=0.2')
            .from(this.DOM.featureJionUs, {
                duration: 1.3,
                ease: 'expo',
                opacity: 0,
                y: '180%',
                stagger: 0.03
            }, 'start+=0.3')
            // content text moves up and fades in
            .from(this.DOM.contentElText, {
                duration: 1.3,
                ease: 'expo',
                y: '180%',
                opacity: 0,
                stagger: 0.03
            }, 'start+=0.7')
            .from(this.DOM.featureList, {
                duration: 1.3,
                ease: 'expo',
                y: '180%',
                opacity: 0,
                stagger: 0.05
            }, 'start+=0.9')

            .from(this.DOM.contactUs, {
                duration: 1.3,
                ease: 'expo',
                y: '180%',
                opacity: 0,
                stagger: 0.03
            }, 'start+=1')
            .from(this.DOM.footer, {
                duration: 1.3,
                ease: 'expo',
                y: '180%',
                opacity: 0,
                stagger: 0.03
            }, 'start+=1.3')
        ;
    }

    close() {
        if (this.timelineHoverOpen) this.timelineHoverOpen.kill();

        this.isContentOpen = false;

        this.timelineHoverClose = gsap.timeline().addLabel('start', 0)
            //content text moves up and fades in
            .to(this.DOM.header, {
                duration: 1.3,
                ease: 'quad.in',
                opacity: 0,
            }, 'start')
            .to(this.DOM.principalTitle, {
                duration: 0.3,
                opacity: 0,
                ease: 'quad.in',
                x: this.invert ? '-180%' : '180%',
            }, 'start')
            .to(this.DOM.contentElHeading, {
                duration: 0.3,
                opacity: 0,
                ease: 'quad.in',
                x: this.invert ? '-103%' : '103%',
            }, 'start')
            .to(this.DOM.contentElText, {
                duration: 1.3,
                ease: 'expo',
                x: '-180%',
                opacity: 0,
                stagger: -0.03
            }, 'start+=0.4')
            .to(this.DOM.featureList, {
                duration: 1.3,
                ease: 'expo',
                x: '180%',
                opacity: 0,
                stagger: 0.05
            }, 'start+=0.5')
            .to(this.DOM.featureJionUs, {
                duration: 1.3,
                ease: 'expo',
                x: '180%',
                opacity: 0,
                stagger: 0.03
            }, 'start+=0.7')
            .to(this.DOM.contactUs, {
                duration: 1.3,
                ease: 'expo',
                x: '-180%',
                opacity: 0,
                stagger: 0.03
            }, 'start+=0.9')
            .to(this.DOM.footer, {
                duration: 0.5,
                ease: 'power4.in',
                opacity: 0,
                y: '20%'
            }, 'start+=1')
    }
}
