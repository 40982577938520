import { gsap } from 'gsap';
import { lerp, getMousePos } from './utils';
import $ from "jquery";

// Track the mouse position
let mouse = {x: 0, y: 0};
//window.addEventListener('mousemove', ev => mouse = getMousePos(ev));

export class Nav {
    constructor(el) {
        this.DOM = {el: el};
        this.DOM.el.style.opacity = 0;
        this.DOM.el.style.position = 'fixed'
        this.DOM.el.style.display = 'flex'
        this.bounds = this.DOM.el.getBoundingClientRect();
        const parent = this.DOM.el.parentNode;
        const parenBounds = parent.getBoundingClientRect();


        this.renderedStyles = {
            tx: {previous: 0, current: 0, amt: 0.2},
            ty: {previous: 0, current: 0, amt: 0.2},
            // top: {previous: 0, current: 0, amt: 0.2},
            // left: {previous: 0, current: 0, amt: 0.2},
            scale: {previous: 1, current: 1, amt: 0.15},
        };

       // el.style.display = 'block';

        this.onMouseMoveEv = (e) => {
            let top = e.pageY - (parenBounds.top+ window.scrollY) ;
            let left = e.pageX - (parenBounds.left + window.scrollX) ;
            mouse = {
                x : left,
                y : top
            }
            this.renderedStyles.tx.previous = this.renderedStyles.tx.current =  mouse.x - (this.bounds.width/2);
            this.renderedStyles.ty.previous = this.renderedStyles.ty.previous = mouse.y -  (this.bounds.height);
            requestAnimationFrame(() => this.render());
            //parent.removeEventListener('mousemove', this.onMouseMoveEv);
        };

        this.onMouseleave = (e) => {
            //this.DOM.el.style.opacity =0;
            gsap.set(this.DOM.el, { opacity: 0});
            console.log('leave')
            debugger;
        };


        this.onMouseEnter = (e) => {
            gsap.to(this.DOM.el, {duration: 0.01, ease: 'Power3.easeOut', opacity: 1});
            console.log('enter')
            debugger;
        };

        parent.addEventListener('mouseenter', this.onMouseEnter);
        parent.addEventListener('mousemove', this.onMouseMoveEv);
        parent.addEventListener('mouseleave', this.onMouseleave);
    }

    enter() {
        this.renderedStyles['opacity'].current = 1;
    }
    leave() {
        this.renderedStyles['opacity'].current = 0;
    }
    render() {
        this.renderedStyles['tx'].current = mouse.x - (this.bounds.width/2);
        this.renderedStyles['ty'].current = mouse.y - (this.bounds.height);

        for (const key in this.renderedStyles ) {
            this.renderedStyles[key].previous = lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
        }
        this.DOM.el.style.transform = `translateX(${(this.renderedStyles['tx'].previous)}px) translateY(${this.renderedStyles['ty'].previous}px) scale(${this.renderedStyles['scale'].previous})`;

       // requestAnimationFrame(() => this.render());
    }
}
