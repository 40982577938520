import {preloadImages, preloadFonts} from './utils';
import {Cursor} from './cursor';
import {Home} from "./Home";
import barba from "@barba/core";
import {delay} from "./utils";
import $ from "jquery";
import {About} from "./About";
import {Expertise} from "./Expertise";
import {selector} from "gsap/gsap-core";
import {Teams} from "./Teams";
import {Contact} from "./Contact";


async function updateMenu(selector) {

    const cursorSelector = selector ? selector + ' .cursor' : '.cursor';
    const navSelector = selector ? selector + ' nav' : 'nav';
    const clickableSelector = selector ? selector + ' a, ' + selector + ' .unbutton' : 'a, .unbutton';
    // initialize custom cursor
    const cursor = document.querySelector(cursorSelector);

    const cursorInstance = new Cursor(cursor);


    [...document.querySelectorAll(clickableSelector)].forEach(link => {
        link.addEventListener('mouseenter', () => cursorInstance.enter());
        link.addEventListener('mouseleave', () => cursorInstance.leave());
    });


    $(navSelector).hover(function () {
        cursor.classList.add('dark')
    }, function () {
        cursor.classList.remove('dark')
    });

    $('.burger').on('click', function () {
        $('.burger__open').addClass('burger__effect');
        $('.burger__close').removeClass('burger__effect');
        $('nav').toggleClass('open')
        // tl.from('nav.open li', {
        //     stagger: 0.2,
        //     duration: .8,
        //     opacity: 0,
        //     y: 40,
        // })
        $('header').toggleClass('header-show')
    })
    $('nav a').each(function (index) {
        $(this).on('mouseover', function () {
            $('nav a').not($(this)).addClass('hover')
        })
        $(this).on('mouseleave', function () {
            $('nav a').not($(this)).removeClass('hover')
        })
    })

    $(window).scroll(function () {
        if ($(window).scrollTop() > 300) {
            $('header').addClass('fixed')
        } else {
            $('header').removeClass('fixed')
        }
    })
    $(window).scroll(function () {
        if ($(window).scrollTop() > 50) {
            $('.wrapper[data-barba-namespace="expertise"]').find('header').css('position', 'fixed');
            $('.wrapper[data-barba-namespace="expertise"]').find('header').css('top', '0');
            $('.wrapper[data-barba-namespace="expertise"]').find('.header__logo__img').fadeOut();
            $('.wrapper[data-barba-namespace="equipe"]').find('header').css('position', 'fixed');
            $('.wrapper[data-barba-namespace="equipe"]').find('header').css('top', '0');
            $('.wrapper[data-barba-namespace="equipe"]').find('.header__logo__img').fadeOut();
        } else {
            $('header').css('position', 'relative')
            $('.wrapper[data-barba-namespace="expertise"]').find('.header__logo__img').fadeIn();
            $('.wrapper[data-barba-namespace="equipe"]').find('.header__logo__img').fadeIn();
        }
    })

    $('.up a').on('click', function (e) {
        e.preventDefault()
        $('html, body').animate({scrollTop: 0}, '300')
    })
    $('.js_hover').on('mousemove', function (e) {
        $('.text__nav').css('opacity', '1')
    })
}


// Preload images and fonts
Promise.all([preloadImages('img, body')]).then(() => {
    // remove loader (loading class)
    document.body.classList.remove('loading');
    let itemsArr = [];
    let scrollValues = {};
    let home = null;
    let about = null;
    let expertise = null;
    let contact = null;
    let teams = null;
    updateMenu();


    barba.init({
        sync: true,
        transitions: [
            {
                async leave(data) {
                    const done = this.async()
                    // $('.cursor').hide()
                    // $('body').css('cursor', 'none');
                    let dt = 1500;
                    document.querySelector('.cursor')?.remove()
                    await delay(dt)
                    //document.querySelector('.cursor').style.display= 'none';
                    done()
                },

                async enter(data) {
                    // // initialize custom cursor
                    const selector = '[data-barba-namespace="' + data.next.namespace + '"]';
                    await updateMenu(selector)
                    setTimeout(function () {
                        window.scrollTo(0, 0);
                    }, 100);
                },

                async once(data) {

                },
            },
        ],
        views: [{
            namespace: 'home',
            afterEnter(data) {
                // if (!home) {
                home = (new Home(document.querySelector('[data-barba-namespace="home"]'), itemsArr));
                // }
                home.open();
            },
            beforeLeave(data) {
                home = (new Home(document.querySelector('[data-barba-namespace="home"]'), itemsArr));
                home.close();
            }
        },
            {
                namespace: 'about',
                afterEnter(data) {
                    about = (new About(document.querySelector('[data-barba-namespace="about"]'), itemsArr));
                    about.open();
                },
                beforeLeave(data) {
                    about = (new About(document.querySelector('[data-barba-namespace="about"]'), itemsArr));
                    about.close();
                }
            },
            {
                namespace: 'expertise',
                afterEnter(data) {
                    expertise = (new Expertise(document.querySelector('[data-barba-namespace="expertise"]'), itemsArr));
                    expertise.open(data.next.url.hash);
                },
                beforeLeave(data) {
                    expertise = (new Expertise(document.querySelector('[data-barba-namespace="expertise"]'), itemsArr));
                    expertise.close();
                }
            },
            {
                namespace: 'equipe',
                afterEnter(data) {
                    let selector = '#warpper img, img'
                    Promise.all([preloadImages(selector)]).then(() => {
                        teams = (new Teams(document.querySelector('[data-barba-namespace="equipe"]'), itemsArr));
                        teams.open();
                    })
                },
                beforeLeave(data) {
                    teams = (new Teams(document.querySelector('[data-barba-namespace="equipe"]'), itemsArr));
                    teams.close();
                }
            },
            {
                namespace: 'contact',
                afterEnter(data) {
                    contact = (new Contact(document.querySelector('[data-barba-namespace="contact"]'), itemsArr));
                    contact.open();
                },
                beforeLeave(data) {
                    contact = (new Contact(document.querySelector('[data-barba-namespace="contact"]'), itemsArr));
                    contact.close();
                }
            }]
    })

    barba.hooks.beforeLeave((data) => {
        if ($('body').scrollTop() !== 0 || $('html').scrollTop() !== 0) {
            $('html, body').animate({scrollTop: 0}, 'fast');
        }
    });

    barba.hooks.beforeLeave((data) => {
        if ($('body').scrollTop() !== 0 || $('html').scrollTop() !== 0) {
            $('html, body').animate({scrollTop: 0}, 'fast');
        }
    });


});
