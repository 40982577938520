import {gsap} from 'gsap';
import $ from "jquery";
import {calcWinsize, wrapElements} from './utils';
import {Nav} from "./Nav";

// Calculate the viewport size
let winsize = calcWinsize();
window.addEventListener('resize', () => winsize = calcWinsize());

export class Teams {
    constructor(el, itemsArr) {
        this.DOM = {el: el};
        this.itemsArr = itemsArr;
        // left/right(invert) align
        this.invert = this.DOM.el.classList.contains('item--invert');


        // image
        this.DOM.img = this.DOM.el.querySelector('.principal__img img');

        this.DOM.principalTitle= this.DOM.el.querySelector('.principal__title');
        // principal__text
        this.DOM.pricipalText = this.DOM.el.querySelector('.principal__text');
        this.DOM.heading = this.DOM.pricipalText.querySelector('h1');

        this.DOM.footer = document.querySelector('footer');

        this.DOM.featureList = document.querySelectorAll('.text__list li');

        this.DOM.listTeams = document.querySelectorAll('.list__equipe');

        this.DOM.header = document.querySelector('header');

        // create the heading texts structure for the characters sliding animation (split the text into characters)
        this.editContentLayout();

        // excerpt element
        this.DOM.principalNav = this.DOM.el.querySelector('.principal__nav');
        this.DOM.textNav = this.DOM.el.querySelector('.text__nav.fill');

        this.initEvents();
    }

    editContentLayout() {
        this.DOM.contentElHeading = this.DOM.pricipalText.querySelector('h1');
        this.DOM.contentHeadingChars = [...this.DOM.contentElHeading.querySelectorAll('.char')];
        //wrapElements(this.DOM.contentHeadingChars, 'span', 'char-wrap');
        this.DOM.contentElText = [...this.DOM.pricipalText.querySelectorAll('p')];
    }


    onMouseEnter() {
        this.DOM.principalNav.addEventListener('mousemove', this.onMouseMove.bind(this))
    }

    onMouseLeave() {
        gsap.to( this.DOM.textNav, {duration:0.8,}
        )
        this.DOM.textNav.style.opa = 'none'
        this.DOM.principalNav.removeEventListener('mousemove', this.onMouseMove.bind(this))
    }

    onMouseMove(e) {
        let top = e.pageY - $('.principal__nav').offset().top - (this.DOM.textNav.clientHeight / 2);
        let left = e.pageX - $('.principal__nav').offset().left - (this.DOM.textNav.clientWidth / 2);
        this.DOM.textNav.style.top = top + 'px';
        this.DOM.textNav.style.left = left + 'px';
        //this.DOM.textNav.style.display = 'flex'
    }

    initEvents() {
        const nav = new Nav(this.DOM.textNav);
        // this.DOM.principalNav.addEventListener('mouseenter', this.onMouseEnter.bind(this),false)
        // this.DOM.principalNav.addEventListener('mouseleave', this.onMouseLeave.bind(this),false)
    }


    open() {

        if (this.timelineHoverIn) this.timelineHoverIn.kill();
        if (this.timelineHoverClose) this.timelineHoverClose.kill();

        this.isContentOpen = true;
        // scroll related
        document.body.classList.add('oh');

        this.timelineHoverOpen = gsap.timeline().addLabel('start', 0)
            .set(
                this.DOM.pricipalText,
                {
                    'clip-path': 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)',
                    opacity: 1,
                    y: 0,
                }
            )
            // .to('.principal__img img', {duration: .5, opacity: 1, x: 0}, '-=.3')

            // animate in the content chars
            .from(this.DOM.header, {
                duration: 1.3,
                ease: 'expo',
                opacity: 0,
            }, 'start')
            .from(this.DOM.principalTitle, {
                duration: 1.3,
                ease: 'expo',
                opacity: 0,
                y: '180%',
                stagger: 0.03
            }, 'start+=0.1')
            .from(this.DOM.contentElHeading, {
                duration: 1.3,
                ease: 'expo',
                opacity: 0,
                y: '180%',
                stagger: 0.03
            }, 'start+=0.1')
            // content text moves up and fades in
            .from(this.DOM.contentElText, {
                duration: 1.3,
                ease: 'expo',
                y: '180%',
                opacity: 0,
                stagger: 0.03
            }, 'start+=0.7')
            .from(this.DOM.listTeams,
                {
                    duration: 1.3,
                    ease: 'expo',
                    y: '180%',
                    opacity: 0,
                    stagger: 0.05
                }, 'start+=0.8')

            .from(this.DOM.principalNav, {
                duration: 1.3,
                ease: 'expo',
                y: '180%',
                opacity: 0,
                stagger: 0.03
            }, 'start+=1')
            .from(this.DOM.footer, {
                duration: 1.3,
                ease: 'expo',
                y: '180%',
                opacity: 0,
                stagger: 0.03
            }, 'start+=1.3')
        ;

        this.DOM.listTeams.forEach((el, index) => {
            $(el).on('click', function () {
                if ($('.equipe__detail').not($(el).find('.equipe__detail')).hasClass('show')) {
                    //$('html, body').animate({scrollTop: '='+$(this).height()+''}, 800);
                    $('.equipe__detail')
                        .not($(el).find('.equipe__detail'))
                        .removeClass('show')
                    setTimeout(function(){
                        gsap.timeline().to(window, {duration: .3, scrollTo: {y: "#" + el.id, offsetY: 200}})
                        $(el).find('.equipe__detail').toggleClass('show');
                    }, 500);
                }else {
                    $(el).find('.equipe__detail').toggleClass('show')
                }
                $(el).find('.equipe__name').toggleClass('white')
                $('.equipe__name')
                    .not($(el).find('.equipe__name'))
                    .removeClass('white')
                $('.equipe__detail')
                    .not($(el).find('.equipe__detail'))
                    .removeClass('show')
                $(el).find('.detail__img').toggleClass('img-show')
                $('.detail__img')
                    .not($(el).find('.detail__img'))
                    .removeClass('img-show')
                $(el).find('.jq-img').toggleClass('img-hide')
                $('.jq-img').not($(this).find('.jq-img')).removeClass('img-hide')
                $(el).find('button').toggleClass('rotate-x')
                $('button').not($(this).find('button')).removeClass('rotate-x')
            })
        })
    }

    close() {
        if (this.timelineHoverOpen) this.timelineHoverOpen.kill();

        this.isContentOpen = false;

        this.timelineHoverClose = gsap.timeline().addLabel('start', 0)
            //content text moves up and fades in
            .to(this.DOM.header, {
                duration: 1.3,
                ease: 'quad.in',
                opacity: 0,
            }, 'start')
            .to(this.DOM.principalTitle, {
                duration: 0.3,
                opacity: 0,
                ease: 'quad.in',
                x: this.invert ? '-180%' : '180%',
            }, 'start')
            .to(this.DOM.contentElHeading, {
                duration: 0.3,
                opacity: 0,
                ease: 'quad.in',
                x: this.invert ? '-103%' : '103%',
            }, 'start')
            .to(this.DOM.contentElText, {
                duration: 1.3,
                ease: 'expo',
                x: '-180%',
                opacity: 0,
                stagger: -0.03
            }, 'start+=0.4')
            .to(this.DOM.listTeams, {
                duration: 1.3,
                ease: 'expo',
                x: '180%',
                opacity: 0,
                stagger: 0.05
            }, 'start+=0.5')
            .to(this.DOM.principalNav, {
                duration: 0.5,
                ease: 'power4.in',
                opacity: 0,
                y: '20%'
            }, 'start')
            .to(this.DOM.footer, {
                duration: 0.5,
                ease: 'power4.in',
                opacity: 0,
                y: '20%'
            }, 'start')
    }
}
