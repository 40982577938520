import {gsap} from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {calcWinsize} from './utils';
import $ from "jquery";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
// Calculate the viewport size
let winsize = calcWinsize();
window.addEventListener('resize', () => winsize = calcWinsize());

export class Expertise {
    constructor(el, itemsArr) {
        this.DOM = {el: el};
        this.itemsArr = itemsArr;
        // left/right(invert) align
        this.invert = this.DOM.el.classList.contains('item--invert');
        // image
        this.DOM.img = this.DOM.el.querySelector('.principal__img img');

        this.DOM.block = document.querySelectorAll('.second__block')

        // principal__text
        this.DOM.principalTitle= this.DOM.el.querySelector('.principal__title');


        this.DOM.footer = document.querySelector('footer');

        this.DOM.header = document.querySelector('.container__header');
        // create the heading texts structure for the characters sliding animation (split the text into characters)
        this.editContentLayout();
        // excerpt element
        this.DOM.principalNav = this.DOM.el.querySelector('.principal__nav');
        this.DOM.textNav = this.DOM.el.querySelector('.text__nav.fill');
        this.initEvents();
    }

    editContentLayout() {
        // this.DOM.contentElHeading = this.DOM.pricipalText.querySelector('h1');
        // this.DOM.contentHeadingChars = [...this.DOM.contentElHeading.querySelectorAll('.char')];
        // //wrapElements(this.DOM.contentHeadingChars, 'span', 'char-wrap');
        //
        // this.DOM.contentElText = [...this.DOM.pricipalText.querySelectorAll('p')];
    }

    onMouseEnter() {
        this.DOM.textNav.style.position = 'absolute'
        this.DOM.textNav.style.display = 'flex'
        this.DOM.principalNav.addEventListener('mousemove', this.onMouseMove.bind(this))
    }

    onMouseLeave() {
        this.DOM.textNav.style.display = 'none'
        window.removeEventListener('mousemove', this.onMouseMove.bind(this))
    }

    onMouseMove(e) {
        var top = e.pageY - $('.principal__nav').offset().top - (this.DOM.textNav.clientHeight / 2);
        var left = e.pageX - $('.principal__nav').offset().left - (this.DOM.textNav.clientWidth / 2);
        this.DOM.textNav.style.top = top + 'px';
        this.DOM.textNav.style.left = left + 'px';
    }

    initEvents() {
        this.DOM.principalNav.addEventListener('mouseenter', this.onMouseEnter.bind(this))
        this.DOM.principalNav.addEventListener('mouseleave', this.onMouseLeave.bind(this))
    }


    open(hash = null) {


        if (this.timelineHoverIn) this.timelineHoverIn.kill();

        this.timelineHoverOpen = gsap.timeline().addLabel('start', 0)
            .set(
                this.DOM.pricipalText,
                {
                    'clip-path': 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)',
                    opacity: 1,
                    y: 0,
                }
            );
        if (hash) {
            this.timelineHoverOpen.to(window, {duration: .3, scrollTo: {y: "#" + hash, offsetY: 200}});
            this.timelineHoverOpen.to(document.body, {duration: .3, scrollTo: {y: "#" + hash, offsetY: 200}});
        }

        this.DOM.block.forEach((el, index) => {
            if (index !== 0 && !hash) {
                var tl1 = gsap.timeline({
                    scrollTrigger: {
                        trigger: el,
                        start: 'top 80%',
                    },
                    defaults: {ease: 'expo', duration: 1.3}
                })
                tl1.from(
                    el.getElementsByClassName('block__title'),
                    {y: 200, opacity: 0},
                    'start',
                ).from(
                    el.getElementsByClassName('block__text'),
                    {y: 200, opacity: 0},
                    'start',
                ).from(
                    el.querySelectorAll('li'),
                    {y: 200, opacity: 0},
                    'start+=.2',
                ).from(
                    el.getElementsByClassName('cta-contact'),
                    {y: 200, opacity: 0},
                    'start+=.6'
                )
            } else {
                this.timelineHoverOpen.from(
                    el.getElementsByClassName('block__title'),
                    {
                        duration: 1.3,
                        ease: 'expo',
                        opacity: 0,
                        y: '180%',
                        stagger: 0.03
                    },
                    'satrt'
                ).from(
                    el.getElementsByClassName('block__text'),
                    {
                        duration: 1.3,
                        ease: 'expo',
                        opacity: 0,
                        y: '180%',
                        stagger: 0.03
                    },
                    'start'
                ).from(
                    el.querySelectorAll('li'),
                    {
                        duration: 1.3,
                        ease: 'expo',
                        opacity: 0,
                        y: '180%',
                        stagger: 0.03
                    },
                    'start'
                ).from(
                    el.getElementsByClassName('cta-contact'),
                    {
                        duration: 1.3,
                        ease: 'expo',
                        opacity: 0,
                        y: '180%',
                        stagger: 0.03
                    },
                    'start+=.6'
                )
            }
        })


        this.timelineHoverOpen
            .from(this.DOM.header, {
                duration: 1.3,
                ease: 'expo',
                opacity: 0,
            }, 'start')
            .from(this.DOM.principalTitle, {
                duration: 1.3,
                ease: 'expo',
                y: '180%',
                opacity: 0,
                stagger: 0.03
            }, 'start')
            .from(this.DOM.principalNav, {
                duration: 1.3,
                ease: 'expo',
                y: '180%',
                opacity: 0,
                stagger: 0.03
            }, 'start+=1')
            .from(this.DOM.footer, {
                duration: 1.3,
                ease: 'expo',
                y: '180%',
                opacity: 0,
                stagger: 0.03
            }, 'start+=1.3')
        ;

    }

    close() {
        if (this.timelineHoverOpen) this.timelineHoverOpen.kill();

        this.isContentOpen = false;

        this.timelineHoverClose = gsap.timeline().addLabel('start', 0);

        this.DOM.block.forEach((el, index) => {

            this.timelineHoverClose.to(
                el.getElementsByClassName('block__title'),
                {
                    duration: 1.3,
                    ease: 'expo',
                    opacity: 0,
                    x: '-180%',
                    stagger: 0.03
                },
                'satrt'
            ).to(
                el.getElementsByClassName('block__text'),
                {
                    duration: 1.3,
                    ease: 'expo',
                    opacity: 0,
                    x: '180%',
                    stagger: 0.03
                },
                'start'
            ).to(
                el.getElementsByClassName('cta-contact'),
                {
                    duration: 1.3,
                    ease: 'expo',
                    opacity: 0,
                    x: '180%',
                    stagger: 0.03
                },
                'start'
            )
        })
        //content text moves up and fades in
        this.timelineHoverClose
            .to(this.DOM.principalTitle, {
                duration: 1.3,
                ease: 'expo',
                opacity: 0,
                x: '-180%',
            }, 'start')
            .to(this.DOM.header, {
                duration: 1.3,
                ease: 'quad.in',
                opacity: 0,
            }, 'start')
            .to(this.DOM.principalNav, {
                duration: 0.5,
                ease: 'power4.in',
                opacity: 0,
                y: '20%'
            }, 'start')
            .to(this.DOM.footer, {
                duration: 0.5,
                ease: 'power4.in',
                opacity: 0,
                y: '20%'
            }, 'start')
    }
}
